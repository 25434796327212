body {
  font-family: "Open Sans", sans-serif;
  &.weight600 {
    font-weight: 600;
  }
}

html {
  scroll-behavior: smooth;
}

.navbar {
  padding: 0;
}

.theme {
  padding: 16px;
}
.wrapper {
  padding-bottom: 5rem;
}
.header {
  border-top: 3px solid #fff;
  padding-top: 5px;
  padding-bottom: 5px;
}

.theme-white {
  color: #666;
  background-color: #fff;
}

.theme-logo {
  color: #fff;
  background-color: #215d88;
}

.theme-dark {
  background-color: #333940;
  color: #fff !important;
}

.theme-menu {
  background-color: #343a40;
  padding-left: 16px;
  padding-right: 16px;
  color: #fff;
}

.theme-primary {
  color: #fff;
  background-color: #122f44;
}

.theme-footer {
  margin-top: 1rem;
  background-color: #07141a;
  color: #fff;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}
.theme-footer a {
  color: #fff;
}
.theme-footer ul {
  list-style-type: none;
  margin-left: 10px;
  padding: 0;
}

.powered-by {
  text-align: right;
}
.powered-by img {
  max-width: 100px;
}

.h1Divider {
  border-bottom: 3px solid #fff;
  max-width: 50px;
}

.h1Divider2 {
  border-bottom: 3px solid #666;
  max-width: 50px;
}

.theme-grey {
  background-color: #f2f2f2;
  color: #000;
}

.bg-light.spacer {
  min-height: 1px;
}

.services .card {
  min-height: 283px;
}

.header a {
  color: #333;
}

.btn-sq-lg {
  width: 150px !important;
  height: 150px !important;
  margin-top: 10px;
}

.btn-sq {
  width: 100px !important;
  height: 100px !important;
  font-size: 10px;
}

.btn-sq-sm {
  width: 50px !important;
  height: 50px !important;
  font-size: 10px;
}

.btn-sq-xs {
  width: 25px !important;
  height: 25px !important;
  padding: 2px;
}

.logo img {
  min-width: 150px;
}

.sponsers {
  img {
    max-width: 249px !important;
  }
}

.nav-item {
  a {
    color: #666;
  }
}

._loading_overlay_wrapper {
  ._loading_overlay_overlay {
    background: #fff;
  }
  ._loading_overlay_content {
    position: absolute;
    top: 50px;
    left: 50%;
    color: #666 !important;
  }
  ._loading_overlay_spinner {
    svg circle {
      stroke: red;
    }
  }
}

.required:after {
  content: " *";
  color: red;
}

.badge {
  color: #fff;
}

table {
  a {
    color: #333;
    text-decoration: underline;
  }
  a:hover {
    color: #333;
    text-decoration: none;
  }
}
