$fa-font-path: "../webfonts";

.wrapper-class {
  padding: 1rem;
  border: 1px solid #ccc;
}
.editor-class {
  border: 1px solid #ccc;
}
.toolbar-class {
  border: 1px solid #ccc;
}

.jobs-table {
  padding-bottom: 70px;
}
